import { useState } from "react";
import { Plugin, createPluginWebhook } from "src/shared/api";
import Input from "src/shared/components/Input";
import Modal from "src/shared/components/Modal";
import { useModalContext } from "src/shared/context/ModalProvider";
import { useNotificationContext } from "src/shared/context/NotificationProvider";
import { EventKind } from "src/shared/events";
import { log } from "src/shared/logger";

type PluginWebhookValues = {
  uri: string;
};

type PluginWebhookErrors = {
  [key in keyof PluginWebhookValues]: string;
};

interface PluginWebhookModalProps {
  plugin: Plugin;
  refetch: () => void;
}

function NewPluginWebhookModal({ plugin, refetch }: PluginWebhookModalProps) {
  const { closeModal } = useModalContext();
  const [values, setValues] = useState<PluginWebhookValues>({
    uri: "",
  });
  const [errors, setErrors] = useState<PluginWebhookErrors>({
    uri: "",
  });

  const { sendErrorNotification, sendSuccessNotification } =
    useNotificationContext();
  const [loading, setLoading] = useState(false);

  const savePluginWebhook = async () => {
    const nextErrors: PluginWebhookErrors = {
      uri: "",
    };

    if (!values.uri) {
      nextErrors.uri = "Webhook URI is required";
    }

    try {
      new URL(values.uri);
    } catch {
      nextErrors.uri = "Invalid URI";
    }

    setErrors(nextErrors);

    if (!Object.entries(nextErrors).some(([_, value]) => value)) {
      try {
        setLoading(true);
        const pluginWebhook = await createPluginWebhook({
          pluginId: plugin.id,
          uri: values.uri,
        });
        sendSuccessNotification({ title: "Plugin webhook created!" });
        log(EventKind.PluginWebhookCreated, {
          pluginId: plugin.id,
          pluginWebhookId: pluginWebhook?.id,
        });
        refetch();
        closeModal();
      } catch (e: any) {
        const title = e?.response?.data?.error || "Something went wrong";
        sendErrorNotification({ title });
      } finally {
        setLoading(false);
      }
    }
  };

  const updateValues = (key: keyof PluginWebhookValues, value: string) => {
    setValues({ ...values, [key]: value });
    setErrors({ ...errors, [key]: "" });
  };

  return (
    <Modal
      title="New plugin webhook"
      onClose={closeModal}
      dialogue={{
        primary: { onClick: savePluginWebhook, loading, title: "Create" },
        secondary: { onClick: closeModal },
      }}
    >
      <Input
        label="Webhook URI"
        onChange={(e) => updateValues("uri", e.target.value)}
        value={values.uri}
        error={errors.uri}
        placeholder="https://example.com/webhook"
      />
      <p>
        When an event occurs, it will send a POST request to the above endpoint.
      </p>
      <p>
        Before accepting events, you'll want to verify each request.{" "}
        <a
          href="https://docs.mobileassistant.us/docs/webhooks"
          target="_blank"
          rel="noreferrer"
        >
          Learn more in our API docs
        </a>
        .
      </p>
    </Modal>
  );
}

export default NewPluginWebhookModal;
