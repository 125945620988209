import { Plugin, PluginWebhook, deletePluginWebhook, triggerPluginWebhook } from "src/shared/api";
import { Button } from "src/shared/components/Button";
import Input from "src/shared/components/Input";
import Modal from "src/shared/components/Modal";
import { H4 } from "src/shared/components/Text";
import { useModalContext } from "src/shared/context/ModalProvider";
import { useNotificationContext } from "src/shared/context/NotificationProvider";
import { log } from "src/shared/logger";
import { EventKind } from "src/shared/events";

interface EditPluginWebhookModalProps {
  plugin: Plugin;
  pluginWebhook: PluginWebhook;
  refetch: () => void;
}

function EditPluginWebhookModal({
  plugin,
  pluginWebhook,
  refetch,
}: EditPluginWebhookModalProps) {
  const { closeModal } = useModalContext();

  const { sendErrorNotification, sendSuccessNotification } =
    useNotificationContext();

  const doDeletePluginWebhook = async () => {
    try {
      await deletePluginWebhook(pluginWebhook.id);
      sendSuccessNotification({ title: "Plugin webhook deleted!" });
      log(EventKind.PluginWebhookDeleted, {
        pluginId: pluginWebhook.pluginId,
        pluginWebhookId: pluginWebhook.id,
      });
      refetch();
      closeModal();
    } catch (e: any) {
      const title = e?.response?.data?.error || "Something went wrong";
      sendErrorNotification({ title });
    }
  };

  const doTestWebhook = async () => {
    try {
      await triggerPluginWebhook(pluginWebhook.id);
      sendSuccessNotification({ title: "Webhook triggered successfully!" });
    } catch (e: any) {
      const title = e?.response?.data?.error || "Failed to trigger webhook";
      sendErrorNotification({ title });
    }
  };

  return (
    <Modal title="Edit plugin webhook" onClose={closeModal}>
      <H4>Webhook URI</H4>
      <Input value={pluginWebhook.uri} disabled style={{ marginTop: 0 }} />
      <H4>Webhook Secret</H4>
      <Input
        value={pluginWebhook.signingSecret}
        disabled
        type="password"
        style={{ marginTop: 0 }}
      />
      <p>
        Before accepting events, you'll want to verify each request.{" "}
        <a
          href="https://docs.mobileassistant.us/docs/webhooks"
          target="_blank"
          rel="noreferrer"
        >
          Learn more in our API docs
        </a>
        .
      </p>
      <div style={{ display: 'flex', gap: '8px' }}>
        <Button onClick={doTestWebhook}>
          Test Webhook
        </Button>
        <Button danger onClick={doDeletePluginWebhook}>
          Delete
        </Button>
      </div>
    </Modal>
  );
}

export default EditPluginWebhookModal;
