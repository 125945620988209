export enum EventKind {
  UserSignedIn = "USER_SIGNED_IN",
  UserResetPassword = "USER_RESET_PASSWORD",
  UserSignedOut = "USER_SIGNED_OUT",
  PluginWebhookCreated = "PLUGIN_WEBHOOK_CREATED",
  PluginWebhookDeleted = "PLUGIN_WEBHOOK_DELETED",
  PluginInstalled = "PLUGIN_INSTALLED",
  PluginInstallationSynced = "PLUGIN_INSTALLATION_SYNCED",
  PluginUninstalled = "PLUGIN_UNINSTALLED",
  PluginUpdated = "PLUGIN_UPDATED",
  PluginCreated = "PLUGIN_CREATED",
}
