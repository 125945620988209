import { Redirect, Route, Switch } from "react-router-dom";
import { mdiAccountCircle, mdiHistory, mdiPuzzle } from "@mdi/js";

import AccountOverview from "src/account/AccountOverview";
import ForgotPassword from "src/account/ForgotPassword";
import SetPassword from "src/account/SetPassword";
import SignIn from "src/account/SignIn";
import { log } from "./logger";
import PluginList from "src/plugins/PluginList";
import PluginDetail from "src/plugins/PluginDetail";
import PluginInstallations from "src/plugins/PluginInstallations";
import EventList from "src/events/EventList";
import { useEffect, useState } from "react";
import { checkIsPluginAdmin } from "./aws";
import PluginWebhooks from "src/plugins/PluginWebhooks";

const PUBLIC_ROUTES_CONFIG = [
  {
    path: "/",
    exact: true,
    component: (props) => (
      <Redirect to={{ pathname: "/signin", search: props.location.search }} />
    ),
  },
  { path: "/signin", component: SignIn },
  { path: "/forgotpassword", component: ForgotPassword },
  { path: "/setpassword", component: SetPassword },
];

const STANDARD_PRIVATE_ROUTES_CONFIG = [
  { path: "/", exact: true, component: () => <Redirect to="/account" /> },
  {
    path: "/account",
    exact: true,
    component: () => <Redirect to="/account/overview" />,
  },
  { path: "/account/overview", component: AccountOverview },
  { path: "/plugins", exact: true, component: PluginList },
  { path: "/plugins/:id", exact: true, component: PluginDetail },
  {
    path: "/plugins/:id/installations",
    exact: true,
    component: PluginInstallations,
  },
  {
    path: "/plugins/:id/webhooks",
    exact: true,
    component: PluginWebhooks,
  },
];

const ADMIN_PRIVATE_ROUTES_CONFIG = [
  { path: "/", exact: true, component: () => <Redirect to="/plugins" /> },
  {
    path: "/account",
    exact: true,
    component: () => <Redirect to="/account/overview" />,
  },
  { path: "/account/overview", component: AccountOverview },
  { path: "/plugins", exact: true, component: PluginList },
  { path: "/plugins/:id", exact: true, component: PluginDetail },
  {
    path: "/plugins/:id/installations",
    exact: true,
    component: PluginInstallations,
  },
  {
    path: "/plugins/:id/webhooks",
    exact: true,
    component: PluginWebhooks,
  },
  { path: "/events", exact: true, component: EventList },
  { path: "/setpassword", component: SetPassword },
];

const STANDARD_SIDEBAR_NAVIGATION_CONFIG = [
  { to: "/plugins", title: "Plugins", icon: mdiPuzzle },
  { to: "/account", title: "Account", icon: mdiAccountCircle },
];

const ADMIN_SIDEBAR_NAVIGATION_CONFIG = [
  { to: "/plugins", title: "Plugins", icon: mdiPuzzle },
  { to: "/events", title: "History", icon: mdiHistory },
  { to: "/account", title: "Account", icon: mdiAccountCircle },
];

const STANDARD_ACCOUNT_NAVIGATION_CONFIG = [
  { to: "/account/overview", title: "Overview & Security" },
];

function RouteWithSubRoutes(route) {
  if (route.event) {
    log(route.event);
  }

  return (
    <Route component={route.component} exact={route.exact} path={route.path} />
  );
}

function PublicRoutesForApp() {
  const routes = getRoutesWith404(PUBLIC_ROUTES_CONFIG);

  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  );
}

function PrivateRoutesForApp() {
  const [isPluginAdmin, setIsPluginAdmin] = useState(false);
  const routes = getPrivateRoutes(isPluginAdmin);

  useEffect(() => {
    const getIsPluginAdmin = async () => {
      setIsPluginAdmin(await checkIsPluginAdmin());
    };
    getIsPluginAdmin();
  }, []);

  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  );
}

function getPrivateRoutes(isPluginAdmin: boolean) {
  if (isPluginAdmin) {
    return getRoutesWith404(ADMIN_PRIVATE_ROUTES_CONFIG);
  }
  return getRoutesWith404(STANDARD_PRIVATE_ROUTES_CONFIG);
}

function getRoutesWith404(routes) {
  return [...routes, { component: () => <Redirect to="/" /> }];
}

function getAccountRoutes() {
  return STANDARD_ACCOUNT_NAVIGATION_CONFIG;
}

function getSidebarRoutes(isPluginAdmin: boolean) {
  if (isPluginAdmin) {
    return ADMIN_SIDEBAR_NAVIGATION_CONFIG;
  }
  return STANDARD_SIDEBAR_NAVIGATION_CONFIG;
}

function getPluginDetailRoutes(id: string) {
  return [
    { to: `/plugins/${id}`, title: "Overview" },
    { to: `/plugins/${id}/installations`, title: "Installations" },
    { to: `/plugins/${id}/webhooks`, title: "Webhooks" },
  ];
}

export {
  PrivateRoutesForApp,
  PublicRoutesForApp,
  getAccountRoutes,
  getPluginDetailRoutes,
  getSidebarRoutes,
};
