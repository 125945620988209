import { API } from "aws-amplify";
import { EventKind } from "./events";

type PaginatedResponse<T> = {
  data: T[];
  totalCount: number;
  hasMore: boolean;
};

enum PluginStatus {
  Published = "Published",
  Unlisted = "Unlisted",
  Disabled = "Disabled",
}

enum PluginInstallationStatus {
  Installed = "Installed",
  Uninstalled = "Uninstalled",
}

type Plugin = {
  id: string;
  name?: string;
  description?: string;
  logoUri?: string;
  permissions: object;
  disabled?: boolean;
  published: boolean;
  userId: number;
  crmIntegrationId: number;
  createdOn: string;
  updatedOn: string;
};

type PluginInstallation = {
  id: string;
  uuid: string;
  userId: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  pluginId: string;
  createdOn: string;
  updatedOn: string;
  deletedOn: string;
};

export type PluginWebhook = {
  id: string;
  uri: string;
  signingSecret: string;
  pluginId: string;
  createdOn: string;
  updatedOn: string;
  deletedOn: string;
};

type Event = {
  id: string;
  event: EventKind;
  actorId: string;
  actor: string;
  properties: object;
  createdOn: string;
};

const mapSnakeToCamel = (obj: any) => {
  if (obj === null || obj === undefined) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(mapSnakeToCamel);
  }
  if (typeof obj === "object") {
    return Object.keys(obj).reduce((acc, key) => {
      const camelKey = key.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace("-", "").replace("_", "");
      });
      return {
        ...acc,
        [camelKey]: mapSnakeToCamel(obj[key]),
      };
    }, {});
  }
  return obj;
};

const mapCamelToSnake = (obj: any) => {
  if (obj === null || obj === undefined) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(mapCamelToSnake);
  }
  if (typeof obj === "object") {
    return Object.keys(obj).reduce((acc, key) => {
      const snakeKey = key.replace(/([A-Z])/g, ($1) => {
        return "_" + $1.toLowerCase();
      });
      return {
        ...acc,
        [snakeKey]: mapCamelToSnake(obj[key]),
      };
    }, {});
  }
  return obj;
};

const fetchEvents = async (
  afterId?: string | null,
  eventType?: string | null
): Promise<PaginatedResponse<Event>> => {
  try {
    eventType = eventType ? `&event_type=${eventType}` : "";
    const path = afterId
      ? `/events?after_id=${afterId}&limit=50${eventType}`
      : `/events?limit=50${eventType}`;
    return mapSnakeToCamel(await API.get("OPEN_API", path, {}));
  } catch (e) {
    console.error(e);
    return { data: [], totalCount: 0, hasMore: false };
  }
};

const createEvent = async (event: Partial<Event>): Promise<void> => {
  try {
    await API.post("OPEN_API", `/events`, { body: mapCamelToSnake(event) });
  } catch (e) {
    console.error(e);
  }
};

const fetchPlugins = async (
  afterId?: string
): Promise<PaginatedResponse<Plugin>> => {
  try {
    const path = afterId ? `/plugins?after_id=${afterId}` : `/plugins`;
    return mapSnakeToCamel(await API.get("OPEN_API", path, {}));
  } catch (e) {
    console.error(e);
    return { data: [], totalCount: 0, hasMore: false };
  }
};

const fetchPlugin = async (id: string): Promise<Plugin | null> => {
  try {
    return mapSnakeToCamel(await API.get("OPEN_API", `/plugins/${id}`, {}));
  } catch (e) {
    console.error(e);
    return null;
  }
};

const fetchPluginInstallations = async (
  id: string,
  afterId?: string
): Promise<PaginatedResponse<PluginInstallation>> => {
  try {
    const path = afterId
      ? `/plugins/${id}/installations?after_id=${afterId}`
      : `/plugins/${id}/installations`;
    return mapSnakeToCamel(await API.get("OPEN_API", path, {}));
  } catch (e) {
    console.error(e);
    return { data: [], totalCount: 0, hasMore: false };
  }
};

export const fetchPluginWebhooks = async (
  id: string,
  afterId?: string
): Promise<PaginatedResponse<PluginWebhook>> => {
  try {
    const path = afterId
      ? `/plugins/${id}/webhooks?after_id=${afterId}`
      : `/plugins/${id}/webhooks`;
    return mapSnakeToCamel(await API.get("OPEN_API", path, {}));
  } catch (e) {
    console.error(e);
    return { data: [], totalCount: 0, hasMore: false };
  }
};

export const triggerPluginWebhook = async (
  pluginWebhookId: string
): Promise<null> => {
  return mapSnakeToCamel(
    await API.post("OPEN_API", `/plugin_webhooks/${pluginWebhookId}/trigger`, {})
  );
};

export const createPluginWebhook = async (
  pluginWebhook: Partial<PluginWebhook>
): Promise<PluginWebhook | null> => {
  return mapSnakeToCamel(
    await API.post("OPEN_API", `/plugin_webhooks`, {
      body: mapCamelToSnake(pluginWebhook),
    })
  );
};

export const deletePluginWebhook = async (
  pluginWebhookId: string
): Promise<null> => {
  return mapSnakeToCamel(
    await API.del("OPEN_API", `/plugin_webhooks/${pluginWebhookId}`, {})
  );
};

const fetchAllPluginInstallations = async (
  id: string
): Promise<PaginatedResponse<PluginInstallation>> => {
  try {
    const path = `/plugins/${id}/installations?limit=0`;
    return mapSnakeToCamel(await API.get("OPEN_API", path, {}));
  } catch (e) {
    console.error(e);
    return { data: [], totalCount: 0, hasMore: false };
  }
};

const createPlugin = async (
  plugin: Partial<Plugin>
): Promise<Plugin | null> => {
  return mapSnakeToCamel(
    await API.post("OPEN_API", `/plugins`, { body: mapCamelToSnake(plugin) })
  );
};

const createPluginInstallation = async (
  pluginInstallation: Partial<PluginInstallation>
): Promise<Plugin | null> => {
  return mapSnakeToCamel(
    await API.post("OPEN_API", `/plugin_installations`, {
      body: mapCamelToSnake(pluginInstallation),
    })
  );
};

const updatePlugin = async (
  plugin: Partial<Plugin>
): Promise<Plugin | null> => {
  return mapSnakeToCamel(
    await API.put("OPEN_API", `/plugins/${plugin.id}`, {
      body: mapCamelToSnake(plugin),
    })
  );
};

const activatePluginInstallation = async (
  pluginInstallation: Partial<PluginInstallation>
): Promise<PluginInstallation | null> => {
  return mapSnakeToCamel(
    await API.post(
      "OPEN_API",
      `/plugin_installations/${pluginInstallation.id}/activate`,
      {}
    )
  );
};

const deactivatePluginInstallation = async (
  pluginInstallation: Partial<PluginInstallation>
): Promise<PluginInstallation | null> => {
  return mapSnakeToCamel(
    await API.post(
      "OPEN_API",
      `/plugin_installations/${pluginInstallation.id}/deactivate`,
      {}
    )
  );
};

const syncPluginInstallations = async (
  plugin: Partial<Plugin>
): Promise<{ message: string } | null> => {
  return mapSnakeToCamel(
    await API.post(
      "OPEN_API",
      `/plugins/${plugin.id}/sync_crm_installations`,
      {}
    )
  );
};

export {
  createEvent,
  fetchEvents,
  fetchPlugins,
  fetchPlugin,
  createPlugin,
  updatePlugin,
  fetchAllPluginInstallations,
  fetchPluginInstallations,
  createPluginInstallation,
  activatePluginInstallation,
  deactivatePluginInstallation,
  syncPluginInstallations,
  PluginStatus,
  PluginInstallationStatus,
  type PaginatedResponse,
  type Plugin,
  type Event,
  type PluginInstallation,
};
